@import '~bootstrap/scss/bootstrap.scss';

:root {
	--plenoil-blue: #002f73;
	--plenoil-blue-light: #879cbd;
	--plenoil-blue-superlight: #e1ecfd;
	--plenoil-blue-2: #528fb8;
	--plenoil-orange: #ff7a00;
	--plenoil-orange-2: #F8A104;
	--plenoil-yellow: #fcd937;
	--plenoil-black: #231f20;
	--plenoil-gray: #575756;
	--plenoil-green: #007961;
	--plenoil-white: white;
	--plenoil-red: #d23627;

	--plenoil-background: #fff2e7;
	--plenoil-background-gray: #f4f4f4;
}

html, body {
	font-family: Dax; 
	height: 100%;
}

#root {
	height: 100%;
}

.text-plenoil-primary {
	color: var(--plenoil-blue);
}

.text-plenoil-red {
	color: var(--plenoil-red);
}

.text-plenoil-yellow {
	color: var(--plenoil-yellow);
}

.bg-plenoil-primary {
	background: var(--plenoil-blue);
}

.bg-plenoil-orange {
	background: var(--plenoil-orange);
}

.btn-plenoil-primary {
	background: var(--plenoil-blue);
	color: var(--plenoil-white);
}

.btn-plenoil-primary:hover {
	color: var(--plenoil-white);
}

.btn-plenoil-blue-light {
	background: var(--plenoil-blue-light);
	color: white;
}

.btn-plenoil-blue-light:hover {
	background: var(--plenoil-blue-superlight);
}

.btn-plenoil-orange {
	background: var(--plenoil-orange);
	color: var(--plenoil-white);
	border-radius: 30px;
	padding: 5px 20px;
}

.form-check-input:checked {
	background-color: var(--plenoil-blue);
	border-color: var(--plenoil-blue);
}

input[type=number].no-arrows {
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	& {
	  -moz-appearance: textfield;
	}
}




//--------------------------------------------------------------------------------------//
//                                       Parpadeo                                       //
//--------------------------------------------------------------------------------------//

.parpadeo {
	animation-name: parpadeo;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
  
	-webkit-animation-name:parpadeo;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
  }
  
  @-moz-keyframes parpadeo{  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	100% { opacity: 1.0; }
  }
  
  @-webkit-keyframes parpadeo {  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	 100% { opacity: 1.0; }
  }
  
  @keyframes parpadeo {  
	0% { opacity: 1.0; }
	 50% { opacity: 0.0; }
	100% { opacity: 1.0; }
  }